<template>
<div class="row">
  <ul v-if="store.getDisplay() === 'list' && $screen.breakpoint === 's'">
    <SmallFile v-for="file in files" :key="'list' + file.id"
      :file="file">
    </SmallFile>
  </ul>
  <div v-else>
    <div v-for="file in files" :key="file.id" >
      <File class="col s12 m6 l4 xl3 xxl2" :file="file"></File>
      <!-- @updated="handleResize()" -->
    </div>
  </div>
</div>
</template>

<script>
import shared from '../shared'
import { store } from '../store'
import debounce from 'lodash/debounce'

const SmallFile = () => import(
  '../components/SmallFile'
  /* webpackChunkName: "SmallFile" */
  /* webpackPreload: true */
)
const File = () => import(
  '../components/File'
  /* webpackChunkName: "File" */
)

export default {
  name: 'FilesList',
  components: {
    SmallFile, File
  },
  props: {
    files: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      shared: shared,
      store: store,
      // updateCollapsible: false,
      debouncedHandleResize: debounce(this.handleResize, 500)
    }
  },
  computed: {
  },
  methods: {
    handleResize () {
      this.$bus.$emit('updatePushpins', 'Display resized')
      // console.log('hello', this.$screen)
    }
    // initCollapsible () {
    //   var elem = this.$refs.collapsible
    //   // eslint-disable-next-line no-undef
    //   M.Collapsible.init(elem)
    // }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.debouncedHandleResize, { passive: true })
  },
  mounted () {
    // this.initCollapsible()
    window.addEventListener('resize', this.debouncedHandleResize, { passive: true })
    // console.log('hello', this.$screen)
  }
}

</script>
<style scoped>
/* li {
  height: 100px
}
li.active {
  height: auto
} */

img {
  max-height: 90px;
  width: 100px;
  object-fit: cover;
  -o-object-fit: cover;
  margin-right: 15px
}

.col {
  padding: 3px 7px !important
}
</style>
