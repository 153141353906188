<template>
  <div class="preloader-wrapper large active bottom-margin" id="loading" key="loading">
    <div class="spinner-layer spinner-blue-only">
      <div class="circle-clipper left">
        <div class="circle"></div>
      </div><div class="gap-patch">
        <div class="circle"></div>
      </div><div class="circle-clipper right">
        <div class="circle"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingCircle'
}
</script>
<style scoped>
#loading {
  position: fixed;
  bottom: 30px;
  margin-left:-25px;
  z-index: 99;
}
</style>
