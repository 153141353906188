<template>
  <div class="wrapper">
    <div ref="pushpin" :class="[store.getTheme()]" class="pushpin day">
      {{header}}
    </div>
  </div>
</template>

<script>
import { store } from '@/store'
export default {
  name: 'Header',
  data () {
    return {
      store: store,
      pushpin: null,
      parent: null,
      offset: 0
    }
  },
  props: {
    header: {
      type: String,
      required: true
    },
    firstOffset: {
      type: Number
    }
  },
  methods: {
    updatePushpin () {
      const scrollTop = document.documentElement.scrollTop
      const parentRect = this.parent.getBoundingClientRect()
      const elemTop = scrollTop + parentRect.top
      this.pushpin.options.top = elemTop
      this.pushpin.options.offset = this.offset
      this.pushpin.options.bottom = elemTop + parentRect.height
      if (this.$refs.pushpin && this.$refs.pushpin.classList.contains('pinned')) {
        this.$refs.pushpin.style.transition = 'top .3s ease'
        this.$refs.pushpin.style.top = this.offset + 'px'
        setTimeout(() => { if (this.$refs.pushpin) { this.$refs.pushpin.style.transition = null } }, 400)
      }
    }

  },
  mounted () {
    const elem = this.$refs.pushpin
    this.offset = store.navbarOffset[0] + store.navbarOffset[1] + store.navbarOffset[2]
    const scrollTop = document.documentElement.scrollTop
    this.parent = elem.parentElement.parentElement
    const parentRect = this.parent.getBoundingClientRect()
    const elemTop = scrollTop + parentRect.top
    // eslint-disable-next-line no-undef
    this.pushpin = M.Pushpin.init(elem, {
      offset: this.offset,
      top: elemTop,
      bottom: elemTop + parentRect.height
    })
    this.$bus.$on('navbarResize', (S) => {
      this.offset = S[0] + S[1] + S[2] - 1
      setTimeout(() => { this.updatePushpin() }, 100)
    })
    this.$bus.$on('updatePushpins', (S) => {
      setTimeout(() => { this.updatePushpin() }, 100)
    })
    setTimeout(() => { this.updatePushpin() }, 100)
  }
}
</script>
<style scoped>
.day {
  width:100%;
  height:50px;
  border-radius: 0px; /* Rounded corners */
  font-size: 26px; /* Increase font size */
  /* margin-top: 7px;
  margin-bottom: 7px; */
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 700!important;
  /* transition: top .3s ease; */
}
.wrapper {
  height:57px;
  padding-top:7px
}

.pin-top {
  position: relative;
}

.pinned {
  position: fixed !important;
  z-index:99;
  /* left:150px */
}

@media only screen and (max-width : 992px) {
  .pinned {
    left:0;
  }
}

.translate {
  transform: translateY(-20px);
}

</style>
