<template>
  <div>
    <button key="remonter" :class="[store.getTheme()]" v-show="!onTop" @click="toTop" id="top" title="Remonter">
      <font-awesome-icon :icon="['fas', 'chevron-up']" size="lg" />
    </button>
    <div name="slide-fade" class="background below-nav" :class="[store.getTheme()]" tag="div">
      <Header header="Dossiers"></Header>
      <div v-if="files.length" :class="[store.getTheme()]" class="background">
        <FilesList :files="files"></FilesList>
      </div>
    </div>
    <LoadingCircle v-show="store.loading"></LoadingCircle>
  </div>
</template>
<script>
import FilesList from '@/components/FilesList'
import Header from '@/components/Header'

import LoadingCircle from '@/components/LoadingCircle'
// @ is an alias to /src
import shared from '../shared'
import { store } from '../store'
// import debounce from 'lodash/debounce'

export default {
  name: 'Files',
  components: {
    FilesList, LoadingCircle, Header
  },
  data () {
    return {
      onTop: true,
      store: store,
      files: []
      // debouncedScrollListener: debounce(this.onScroll, 1000)
    }
  },
  async created () {
    document.title = 'Mahana Tahiti, toute l\'actualité de Polynésie'
    this.store = store
    this.loadFiles()
  },
  mounted () {
    shared.mesure('files', undefined, undefined, undefined, store.getUserToken())
  },
  beforeMount () {
    window.addEventListener('scroll', this.debouncedScrollListener, { passive: true })
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.debouncedScrollListener, { passive: true })
  },
  watch: {
    $route (to, from) {
      this.loadFiles()
    }
  },
  methods: {
    // async onScroll () {
    //   const bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + 2 * window.innerHeight > document.documentElement.offsetHeight
    //   if (!store.loading && bottomOfWindow) {
    //     await this.loadFiles()
    //   }
    // },
    toTop () {
      var position = document.body.scrollTop || document.documentElement.scrollTop
      var scrollAnimation
      if (position < 100) {
        window.scrollBy(0, -Math.floor(position))
        clearTimeout(scrollAnimation)
      } else {
        window.scrollBy(0, -Math.max(500, Math.floor(position / 2)))
        scrollAnimation = setTimeout(this.toTop, 10)
      }
    },
    async loadFiles () {
      store.loading++
      try {
        // let limit = 12
        // if (this.$screen.breakpoint === 's' && store.getDisplay() === 'list') {
        //   limit = 6
        // } else if (this.$screen.breakpoint === 'l') {
        //   limit = 9
        // } else if (this.$screen.breakpoint === 'm') {
        //   limit = 6
        // } else if (this.$screen.breakpoint === 's') {
        //   limit = 3
        // }
        // let request = `files?limit=${limit}`
        const request = 'files'
        // if (this.files.length > 0) {
        //   const lastFile = this.files[this.files.length - 1]
        //   request = `files?limit=${limit}&startAfter=` + lastFile.title
        // }
        const response = await shared.get(request)
        if (response.data) {
          // this.files = this.files.concat(response.data)
          // this.$set(this, 'files', response.data)
          this.files = response.data
          this.updateImages()
        }
        return response
      } catch (err) {
        // shared.signalerErreur('Problème réseau, chargement impossible')
        // console.log(err)
        return null
      } finally {
        store.loading--
      }
    },
    updateImages () {
      store.loading++
      this.files.forEach(async file => {
        if (!file.illustrationArticleId) {
          console.log('searching')
          // we search for an illustration article
          try {
            const baseRequest = `articles/?search=${file.searchQuery}&limit=1`
            const response = await shared.get(baseRequest)
            if (response.data && response.data.nbHits >= 1) {
              console.log('ok', file.searchQuery, response.data.hits[0].id)
              this.$set(file, 'illustrationArticleId', response.data.hits[0].id)
            } else {
              console.log(response.data)
            }
          } catch (err) {
            return null
          }
        }
      })
      store.loading--
    }
  }
}

</script>
<style scoped>
#top {
  position: fixed; /* Fixed/sticky position */
  bottom: 30px; /* Place the button at the bottom of the page */
  right: 30px; /* Place the button at the right of the page */
  z-index: 99; /* Make sure it does not overlap */
  border: none; /* Remove borders */
  outline: none; /* Remove outline */
  cursor: pointer; /* Add a mouse pointer on hover */
  padding: 15px; /* Some padding */
  border-radius: 10px; /* Rounded corners */
  font-size: 18px; /* Increase font size */
}
#top:hover {
  background-color: rgb(95, 95, 95); /* Add a dark-grey background on hover */
}
</style>
